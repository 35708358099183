import { Checkbox } from 'carbon-components-react'
import './Category.css';
import React, { Component } from 'react';

export class Category extends Component {
    constructor(props) {
        super(props);
        this.state = { isChecked: props.isChecked, id: props.id, iconClass: props.iconClass,
            name: props.name, desc: props.desc, desc2: props.desc2, previouslyUnsubscribed: props.previouslyUnsubscribed  }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isChecked !== prevProps.isChecked) {
            this.setState({ isChecked: this.props.isChecked });
        }

        if (this.props.previouslyUnsubscribed !== prevProps.previouslyUnsubscribed) {
            this.setState({ previouslyUnsubscribed: this.props.previouslyUnsubscribed });
        }
    }

    onChangeHandler = (checked) => {
        
        this.setState((state, props) => {
            return {
                isChecked: checked
            };
        });
       this.props.onPropertyChange({isChecked:checked, id:this.state.id}) // notify the parent component the user chose a category
    }

    render() {
        const { isChecked, id, iconClass, name, desc, desc2, previouslyUnsubscribed } = this.state
        return (
            <li className={`category`} data-testid={id.toString()}>
                <div className="checkbox-title-container">
                    
                        <div className="checkbox-container">
                            <Checkbox disabled={!previouslyUnsubscribed && !isChecked} checked={isChecked} id={id.toString()} labelText={''} onChange={this.onChangeHandler} aria-label={name + ', ' + desc ?? ''} />
                        </div>
                        <div className="cat-text-container">
                            <div className="title-icon-container">
                            {iconClass && <div className={`d-none d-md-block d-lg-block icon ${iconClass}`} />}
                                <span className="category-title" style={{ paddingLeft: '0px' }} >{name}</span>
                            </div>
                            <div className="description1 mt-1">
                                {desc}
                            </div>
                            <div className="description2 mt-1">
                                {desc2}
                            </div>
                        </div>
                </div>
            </li>
        )
    }
} 