import axios from 'axios'

const apiUrl = process.env.REACT_APP_PREFERENCES_UI_API_URL ?? ''
export const callApi = (apiParams) => axios({
        url: apiParams.withNewDomain ? apiParams.url : `${apiUrl}/${apiParams.url}`,
        method: apiParams.method || "GET",
        headers: {
            "Content-Type": "application/json",
            ...apiParams.headers || null
        },
        data: apiParams.data || null,
    })
    .then((response) => response.data)
    .catch(() => false);
