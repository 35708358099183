import { Category } from '../category/Category'
import React, { useState, useEffect } from 'react'
import './Container.css';
import { ComboBox, Button, Alert, Toast } from '@eversource/mf-storybook'
import { trackEvent } from '@eversource/mf-analytics';
import '@eversource/mf-storybook/dist/storybook.scss'
import ReCAPTCHA from 'react-google-recaptcha';
import { callApi } from '../api/callApi'
import { GetEmailAddresses } from '../api/GetEmailAddresses'
import { GetEmailPreferences } from '../api/GetEmailPreferences'
import Loading from '../Loading'
import { DEFAULT_CATEGORIES, toastDisplayDuration } from '../../constants'

export default function Container({ isEmbedded, captchaKey, Email, RecaptchaFeatureFlag }) {
    const [loading, setLoading] = useState(false)
    const [selectedCategories, setSelectedCategories] = useState([])
    const [originalCategories, setOriginalCategories] = useState([])
    const [selectAllCategories, setSelectAllCategories] = useState(false)
    const [submitEnabled, setSubmitEnabled] = useState(false)
    const [categories, setCategories] = useState([])
    const [emailList, setEmailList] = useState([])
    const [email, setEmail] = useState('')
    const [customerId, setCustomerId] = useState(0)
    const [isValidEmail, setIsValidEmail] = useState(false)
    const [tokenKey, setTokenKey] = useState('')
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [urlAlert, setUrlAlert] = useState('#');
    const [emailNotFound, setEmailNotFound] = useState(false);

    useEffect(() => {
        setCategories(DEFAULT_CATEGORIES)
        callApi({ url: 'EmailPreference/GetUrlAlert' })
            .then(response => {
                if (response?.url)
                    setUrlAlert(response.url)
            })
            .catch(error => {
                setUrlAlert('/')
            })
    }, [])

    useEffect(() => {     
        if (Email && Email !== '' && !isEmbedded) {
            getUserPreferences(Email)
        } else {
            const categoriesId = DEFAULT_CATEGORIES.map(category => category.id)
            setSelectedCategories(categoriesId)
            setOriginalCategories(categoriesId)        
        }
    }, [Email])

    useEffect(() => {
        if (isEmbedded) {
            const getEmailsFromLoggedUser = async () => {
                const response = await GetEmailAddresses()
                if (response && response.IsSuccess && response.EmailAddresses && Array.isArray(response.EmailAddresses)) {
                    const emailListLength = response.EmailAddresses.length
                    
                    if (emailListLength === 0) {
                        setEmailNotFound(true)
                    } else {
                        const firstEmailAddress = response.EmailAddresses[0].EmailAddress
                        getUserPreferences(firstEmailAddress)
                        setEmailList(response.EmailAddresses)
                    }
                } else if (response && !response.IsSuccess && response.EmailAddresses === null) {
                    setEmailNotFound(true)
                }
            }
            getEmailsFromLoggedUser()
        }
    }, [isEmbedded])

    const getUserPreferences = async email => {
        setLoading(true)
        setEmail(email)
        setSelectAllCategories(false)
        const preferencesResponse = await GetEmailPreferences(email)
        let categoriesId = []
        if (preferencesResponse) {
            setCustomerId(preferencesResponse.customerId)
            setIsValidEmail(preferencesResponse.customerId != 0)
            if (preferencesResponse.categoryPreferences) {
                preferencesResponse.categoryPreferences.forEach(categorie => {
                    if (categorie.isSubscribed) {
                        categoriesId.push(categorie.categoryId)
                    }
                })
            } else {
                categoriesId = DEFAULT_CATEGORIES.map(category => category.id)
            }
        }
        setLoading(false)
        setSelectedCategories(categoriesId)
        setOriginalCategories(categoriesId)
        setSubmitEnabled(false)
    }

    const onSelectionChange = (data) => {
        let categoryId = data.id;
        let isChecked = data.isChecked;

        const index = selectedCategories.indexOf(categoryId)
        let newCategories = [...selectedCategories]
        if (isChecked) {
            if (index < 0) {
                newCategories.push(categoryId);
            }
        } else {
            if (index >= 0) {
                newCategories = newCategories.filter(function (element) {
                    return element !== categoryId
                });
            }
        }
        setSelectAllCategories(false)
        setSelectedCategories(newCategories)
        setSubmitEnabled(isSubmitEnabled(newCategories))
    }

    const selectAll = () => {
        let newCategories = selectAllCategories 
            ? originalCategories
            : []  
        setSelectedCategories([...newCategories])
        setSelectAllCategories(!selectAllCategories)
        setSubmitEnabled(isSubmitEnabled(newCategories) || !selectAllCategories)
    }

    const isSubmitEnabled = newCategories => {
        const areTheSameAsInitial = (JSON.stringify([...originalCategories].sort()) === JSON.stringify([...newCategories].sort()));
        if (!isValidEmail && RecaptchaFeatureFlag) {
            return tokenKey !== '' && !areTheSameAsInitial
        } else {
            return !areTheSameAsInitial
        }
    }
    
    const submitUpdates = async () => {        
        const categoryPreferences = categories.map(item => ({ "categoryId": item.id, "isSubscribed": selectedCategories.includes(item.id) }))
        try {
            const response = await callApi({
                url: 'EmailPreference/Unsubscribe',
                method: 'POST',
                data: JSON.stringify({
                    "customerId": customerId,
                    "emailAddress": email,
                    "categoryPreferences": categoryPreferences,
                    "isRecognized": isValidEmail,
                    "Response": tokenKey
                })
            })
            setSelectAllCategories(false)
            setFormSubmitted({ isSuccess: response.isSuccess })
            setOriginalCategories([...selectedCategories])
            trackEvent('Unsubscribe submit')           
        } catch (error) {
            setFormSubmitted({ isSuccess: false })
            trackEvent('Unsubscribe submit error')
        } finally {
            setSubmitEnabled(false)
            setTimeout(() => { setFormSubmitted(false) }, toastDisplayDuration)
        }
    }
    const onChangeReCAPTCHA = (value) => {
        setTokenKey(value);       
        setSubmitEnabled(selectedCategories.length > 0 && value !== '');
    }
    return (
        <div className={isEmbedded ? 'email-subscription cms-container--max-width' : 'email-subscription' }>
            <div className={isEmbedded
                ? 'cms-container-gutter'
                : 'container'}
            >
                <h1 className="title" aria-label="Email Subscriptions">Email Subscriptions</h1>
                <div className="rectangle"></div>
                <fieldset tabIndex="0" className="fieldset-container">
                    <legend className="subtitle">Use this page to manage informational emails you receive from Eversource.</legend>
                    <Alert
                        text={<span>
                                Your selections on this page will not alter any account emails you get for outages, billing, etc.<br />
                            <a href={urlAlert} aria-label="Your selections on this page will not alter any account emails you get for outages, billing, etc. View your alerts and notifications to modify">View your alerts and notifications to modify</a>
                        </span>}
                        type="information"
                        customClass="information-container"
                    />
                    {emailList.length > 1
                    ? <>
                        <h2 className="section-header">Select which email address you want to manage</h2>
                        <section className="section">
                            <div className="email-dropdown-container cds--col-sm-4 cds--col-md-8 cds--col-lg-8">
                                <ComboBox
                                    aria-label="Select which email address you want to manage"
                                    titleText="Email"
                                    items={emailList.map(email => email.EmailAddress)}
                                    id='ep-email-select'
                                    onChange={value => {
                                        getUserPreferences(value.selectedItem)
                                    }}
                                    readOnly={ true }
                                    initialSelectedItem={emailList[0].EmailAddress}
                                />
                            </div>
                        </section>
                    </>
                    : !emailNotFound && email && email !== '' && <>
                        <h2 className="section-header">Email Address</h2>
                        <section className="section">
                            {email}
                        </section>
                    </>}
                    {isEmbedded && emailNotFound && <section className="section information-container" tabindex="0">
                        It may take a few days for new accounts to be set up for email marketing.<br /> Please come back later to manage your preferences.
                    </section>}
                    {!emailNotFound && <>
                        <section>
                            <h2 className="section-header">Unsubscribe from all</h2>
                            <ul>
                                <Category
                                    id="unsuscribeFromAll"
                                    name=""
                                    desc="Unsubscribe from all marketing and informational emails"
                                    desc2=""
                                    iconClass={false}
                                    isChecked={selectAllCategories}
                                    onPropertyChange={selectAll}
                                    previouslyUnsubscribed={!(originalCategories && originalCategories.length === 0)}
                                />
                            </ul>
                        </section>
                        <section tabindex="0" aria-labelledby="uncheckItemsHeader">
                            <h2 className="section-header" id="uncheckItemsHeader">Uncheck the email topics you do not wish to receive</h2>
                            <div className="list-container">
                                {loading && <Loading />}
                                <ul>
                                    {categories.map((category, index) => {
                                        const isChecked = selectedCategories.findIndex(x => x === category.id) >= 0;
                                        return <Category id={category.id} name={`${category.name}`} desc={`${category.desc}`} desc2={`${category.desc2}`} key={index} iconClass={category.iconClass} isChecked={isChecked}
                                            onPropertyChange={onSelectionChange} previouslyUnsubscribed={originalCategories && originalCategories.includes(category.id)} />;
                                    })}
                                </ul>
                            </div>
                        </section>

                        <div className={!isValidEmail && RecaptchaFeatureFlag ? 'captcha-container' : 'hidden'}>
                            <ReCAPTCHA
                                sitekey={captchaKey}
                                onChange={onChangeReCAPTCHA}
                                theme={'light'}
                                size={'normal'}
                            />
                        </div>
                        <div className="Button-container">
                            <Button disabled={!submitEnabled} className="Submit-button" onClick={submitUpdates} label="Submit Updates" />
                        </div>
                    </>}
                </fieldset>
            </div>
            <div aria-live="assertive">
            {formSubmitted &&
                <Toast
                    customClass="toast-notification"
                    kind={formSubmitted.isSuccess || (!formSubmitted.isSuccess && !isValidEmail) ? "success" : "error"}
                    title={formSubmitted.isSuccess || (!formSubmitted.isSuccess && !isValidEmail) ? "Update successful!" : "System error"}
                    subtitle={formSubmitted.isSuccess || (!formSubmitted.isSuccess && !isValidEmail) ? "" : "We were unable to process your request. Please try again."}
                    timeout={toastDisplayDuration}
                />}
            </div>
        </div>
    )
}