import { callApi } from './callApi'

export function GetEmailAddresses () {
    return callApi({
        url: '/cg/customer/preferences/marketingemailaddresses',
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true,
        withNewDomain: true
    })
}
