import React from 'react'
import './Loading.css'

export default function loading() {

    return <div className="loadingContainer">
        <div className="center-spinner">
            <svg className="bx--loading__svg" viewBox="0 0 100 100">
                <title></title>
                <circle className="bx--loading__background" cx="50%" cy="50%" r="44"></circle>
                <circle className="bx--loading__stroke" cx="50%" cy="50%" r="44"></circle>
            </svg>
        </div>
    </div >
}