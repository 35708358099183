import './App.css'
import './App.scss'
import React from 'react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { SecureRoute, Security } from '@okta/okta-react/'
import TrackingWrapper from '@eversource/mf-analytics'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Layout from './components/layout/Layout'
import { getConfiguration } from './utils/configurationProvider'

export default function App() {
    const config = getConfiguration()
    const oktaAuth = new OktaAuth({
        clientId: config.oktaClientId,
        issuer: config.oktaIssuer,
        redirectUri: config.oktaRedirectUri,
        pkce: true
    })

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        window.location.href = toRelativeUrl(originalUri ?? '/', window.location.origin)
    }
  
    const customAuthHandler = async () => {
        const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState()
        if ((previousAuthState == null) || !(previousAuthState.isAuthenticated ?? false)) {
            window.location.href = toRelativeUrl(oktaAuth.options.redirectUri, window.location.origin)
        }
    }

    const getCurrentEnvironmentKeys = () => {
        const environmentPerSubdomain = process.env.REACT_APP_ENV_HOSTNAME || ''
        const telemetryKeysPerEnvironment = process.env.REACT_APP_APP_INSIGHTS_KEY || ''
        const adobeKeysPerEnvironment = process.env.REACT_APP_ADOBE_ANALYTICS_KEY || ''
        const clarityKeysPerEnvironment = process.env.REACT_APP_CLARITY_KEY || ''
        let subdomain = window.location.hostname;
        subdomain = subdomain.replace("www.", "")
        const env = JSON.parse(environmentPerSubdomain)[subdomain]
        return {
            appInsights: JSON.parse(telemetryKeysPerEnvironment)[env],
            adobe: JSON.parse(adobeKeysPerEnvironment)[env],
            clarity: JSON.parse(clarityKeysPerEnvironment)[env]
        }
    }

    const analyticKeys = getCurrentEnvironmentKeys()
    return (
        <TrackingWrapper appInsightsKey={analyticKeys.appInsights} adobeKey={analyticKeys.adobe} clarityKey={analyticKeys.clarity}>
            <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
                <BrowserRouter basename={window.location.pathname}>
                <Switch>
                    { config.isEmbedded
                    ? <SecureRoute path='/' exact render={() => <Layout isEmbedded={true} />} />
                    : <Route path='/' exact render={() => <Layout isEmbedded={false} />} />
                    }
                </Switch>
                </BrowserRouter>
            </Security>
        </TrackingWrapper>
  )
}
