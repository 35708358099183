import React from 'react';
import './breadcrumbs.css';

export default function Breadcrumbs(props) {
    return (
            <nav aria-label="breadcrumb" data-testid="breadcrumbsContainer">
            <ol className="d-none d-md-block d-lg-block c-breadcrumb mb-7 md:mb-8">
                    {props.paths && props.paths.length > 0 && props.paths.map(path =>
                        <li className="c-breadcrumb-item" key={path.label}>
                            <a href={path.link} className="c-link">{path.label}</a>
                       </li>)}
                </ol>
            <ol className="d-block d-md-none c-breadcrumb mb-7 md:mb-8 paddingTop">
                    {props.paths && props.paths.length > 0 && props.paths[props.paths.length - 2] &&
                        <li className="c-breadcrumb-mobile-item" key={props.paths[props.paths.length - 2].label}>
                            <a href={props.paths[props.paths.length - 2].link} className="c-link">{props.paths[props.paths.length - 2].label}</a>
                        </li>}
                </ol>
            </nav>)
}