import React, { useState, useEffect } from 'react';
import './Header.css';
import img from '../../images/logo-eversource--main.svg';
import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import { callApi } from '../api/callApi'

export function Header({ isEmbedded }) {
    const [EnviromentURL, setEnviromentURL] = useState('')
    const [paths, setPaths] = useState([])
    
    useEffect(async () => {
        await getEnviromentURL();
    }, []);
    
    useEffect(() => {
        if (isEmbedded) {
            setPaths([
                { label: 'Residential', link: '/content/residential' },
                { label: 'Account & Billing', link: '/content/residential/account-billing' },
                { label: 'Account Settings', link: '/content/residential/account-billing/settings' },
                { label: 'Email Subscriptions', link: '#' }
            ])
        }
    }, [isEmbedded])

    const getEnviromentURL = async () => {
        try {
            const data = await callApi({ url: 'EmailPreference/GetEnviromentURL' })
            if (data)
                setEnviromentURL(data.url);
        }
        catch (error) {
            setEnviromentURL('/');
        }
    }
    return (<div className={isEmbedded ? 'cms-container--max-width' : ''}>
        <div className={isEmbedded ? "cms-container-gutter paddingTop" : "rectangle_un"} >
                {!isEmbedded
                    ? <>
                        <div className="logo" >
                            <a href={`${EnviromentURL}`} >
                                <img src={img} className="c-header__logo--medium_un" alt="eversource" />
                            </a>
                        </div>
                        <div className="c-icon--tricolor"></div>
                      </>
                    : <Breadcrumbs paths={paths} />}
            
            </div>
        </div>
    )
}
