export const getConfiguration = () => {
    let config = {
        isEmbedded: false,
        oktaClientId: process.env.REACT_APP_OKTA_CLIENT_ID ?? '',
        oktaIssuer: process.env.REACT_APP_OKTA_ISSUER ?? '',
        oktaRedirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI ?? ''
    }
    const app = document.getElementById('root')
    const dataConfigFromExternal = app?.attributes['data-config']?.value ?? ''
    if (dataConfigFromExternal.length > 0) {
        const configFromExternal = JSON.parse(dataConfigFromExternal)
        config = { ...config, ...configFromExternal }
    }
    return config
}
