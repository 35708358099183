import 'bootstrap/dist/css/bootstrap.css';
import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import 'polyfill-array-includes'
import 'promise-polyfill/src/polyfill'
import 'string-includes-polyfill'
import 'whatwg-fetch'

const rootElement = document.getElementById('root');

ReactDOM.render(  
  <App />,
  rootElement);