
import React, { useState, useEffect } from 'react'
import Container from '../container/Container';
import { Header } from '../header/Header';
import { callApi } from '../api/callApi'

export default function Layout({ isEmbedded }) {
    const [captchaKey, setCaptchaKey] = useState('');
    const [EmailAddress, setEmailAddress] = useState('');
    const [RecaptchaFeatureFlag, setRecaptchaFeatureFlag] = useState(false);
    const [IsDone, setIsDone] = useState(false);
    useEffect(() => {
        (async () => {
            try {
                let emailAddress
                if (!isEmbedded) {
                    const search = window.location.search;
                    const params = new URLSearchParams(search);
                    emailAddress = params.get('EmailAddress') || params.get('emailaddress') || params.get('EMAILADDRESS') || params.get('emailAddress') || params.get('Emailaddress')

                    const dataFFRecaptcha = await callApi({ url: 'EmailPreference/GetRecaptchaFeatureFlag' })
                    if (dataFFRecaptcha)
                        setRecaptchaFeatureFlag(dataFFRecaptcha.flag);
                }
                setEmailAddress(emailAddress);

                const data = await callApi({ url: 'EmailPreference/GetPublicCaptchaKey' })
                if (data) {
                    setCaptchaKey(data.tokenKey);
                    setIsDone(true);
                }
            }
            catch (error) {

            }
        })();
    }, [])

    if (IsDone) {
        return <>
            <Header isEmbedded={isEmbedded ?? false} />
            <Container isEmbedded={isEmbedded ?? false} captchaKey={captchaKey} Email={EmailAddress} RecaptchaFeatureFlag={RecaptchaFeatureFlag} />
        </>
    }
    return <></>;


}
