export const STATUS_CODE_OK = '200'
export const STATUS_CODE_TOO_MANY_REQUESTS = '429'
export const OKTA_STORAGE = 'okta-token-storage'
export const DEFAULT_CATEGORIES = [
    { id: 1, name: "Eversource News", desc: "Information about Eversource, clean energy, savings, community events and more.", desc2: "", iconClass: "EmailIcon" },
    { id: 2, name: "Safety Tips and Information", desc: "Tips to stay safe around electric and natural gas utilities. ", desc2: "", iconClass: "assignment_late_icon" },
    { id: 3, name: "Save Money and Energy", desc: "Ideas for lowering your energy usage, and rebates and offers on energy efficient products.", desc2: "", iconClass: "payment_icon" },
    { id: 4, name: "Payment Related Programs", desc: "Paperless bills, budget billing and other general payment topics. ", desc2: "", iconClass: "monitization_on_icon" },
    { id: 5, name: "Switching to Natural Gas Heat", desc: "Benefits, offers, and rebates on switching to natural gas heat.", desc2: "", iconClass: "flameicon" },
]
export const toastDisplayDuration = 6000